<template>
    <div>
        <vs-sidebar position-right  parent=".main-wrapper"  color="primary" class="sidebarx" spacer v-model="messageBarActive">

            <div class="header-sidebar" style="text-align:center" slot="header">
                <h4>
                <vs-icon icon="messages"></vs-icon>
                {{$t('COM.MESSAGEBAR.HEADER')}}
                </h4>
            </div>
            <template  v-for="message in getUnreadMessages">
                <vs-sidebar-item :key="message.id + '-mes'">
                    <MessagebarItem :Guid="message.guid" :Title="message.title" :DateTime="message.datetime" :Text="message.text" :From="message.from" :DocumentIds="message.fileIds" :Color="message.color"/>
                </vs-sidebar-item>
            </template>
    
            <div class="footer-sidebar" slot="footer">
                <vs-button class="fullwidth" icon="close" color="danger" type="flat" @click="CloseMessageBar()">{{$t('COM.CLOSE')}}</vs-button>
            </div>

        </vs-sidebar>
        
    </div>
</template>
<script>
import MessagebarItem from "./MessagebarItem";

export default {
    name: "Messagebar",
    components:{
        MessagebarItem
    },
    props:['Active'],
    data: function (){
        return {
            messageBarActive:this.Active
        }
    },
    methods:{
        CloseMessageBar(){
            this.messageBarActive = false;
        },
        ShowMessageBar(){
            this.messageBarActive = true;
        }
    },
    watch:{
        getUnreadMessages(value){
            if(value.length == 0)
            {
                this.CloseMessageBar();
            }
        }
    },
    computed:{
        getUnreadMessages() {
            var data = [];
            if(this.$store.state.messages.messages.data != null)
            { 
                this.$store.state.messages.messages.data.forEach(message=>{
                    if(message.read == false)
                    {
                        data.push(message);
                    }
                    
                });
            }
            return data;
        }
    }
};
</script>
