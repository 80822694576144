<template lang="html">

   <div id="parentx">
    <vs-sidebar default-index="1" :parent="parent" :hiddenBackground="doNotClose" color="primary" class="sidebarx" spacer v-model="isSidebarActive" :click-not-close="doNotClose" >
        <div class="header-sidebar text-center" slot="header">
            <vs-avatar size="70px" v-bind:src="'data:image/jpeg;base64,'+displayUserImg"/>
            <h4>{{ displayName }}</h4>
            <h6>{{displayPartnerID}}</h6>
            <small>{{ displayEmail }}</small>
        </div>
        <div class="header-sidebar text-center" v-if="this.$appConfig.signalRDisabled">
          <small><vs-icon icon="notifications_off"></vs-icon>Servernachrichten deaktiviert</small>
        </div>
        <div class="header-sidebar text-center" v-if="!signalConnected">
          <small><vs-icon icon="notifications_off"></vs-icon>Servernachrichten: verbinden...</small>
        </div>
        <template v-for="(sidebarLink, index) in sidebarLinks" >
            <template v-if="typeof(sidebarLink.sublinks)=='undefined'">
            <can :key="`mnu_can-${index}`" :I="sidebarLink.right.action" :a="sidebarLink.right.subject">
              <vs-sidebar-item :icon="sidebarLink.icon" :to="sidebarLink.url" :index="index">
                <span class="hide-in-minisidebar">{{ sidebarLink.name }}</span>
              </vs-sidebar-item>
            </can>
            </template>
            <template v-if="typeof(sidebarLink.sublinks)!='undefined'">
              <can :key="`mnu_can-${index}`"  :I="sidebarLink.right.action" :a="sidebarLink.right.subject">
                <vs-sidebar-group :title="sidebarLink.name" >
                  <template v-for="(sublink,idx) in sidebarLink.sublinks">
                    <can :key="`mnu_sub_can-${idx}`" :I="sublink.right.action" :a="sublink.right.subject">
                      <vs-sidebar-item :icon="sublink.icon" :to="sublink.url" :index="index+'.'+idx">
                        <span class="hide-in-minisidebar">{{ sublink.name }}</span>
                      </vs-sidebar-item>
                    </can>
                  </template>
                </vs-sidebar-group>
              </can>
            </template>
        </template>
        <vs-sidebar-item icon="cancel" @click="LogoutUser">
              <span class="hide-in-minisidebar">Abmelden</span>
        </vs-sidebar-item>  

    </vs-sidebar>

  </div>


</template>

<script>
import helperMethods from '../../../helper/staticFuncHelper.js';

export default {
  name: "SideBar",
  props: {
    parent: {
      type: String
    },
    sidebarLinks: {
      type: Array,
      required: true
    },
    index: {
      default: null,
      type: [String, Number]
    }
  },
  data: () => ({
    doNotClose: false,
    windowWidth: window.innerWidth
  }),
  computed: {
    //This is for mobile trigger
    isSidebarActive: {
      get() {
        return this.$store.state.sidebar.isSidebarActive;
      },
      set(val) {
        this.$store.commit("sidebar/IS_SIDEBAR_ACTIVE", val);
      }
    },
    signalConnected(){
      return this.$store.state.maincontainer.signalRConnected;
    },
    firstName() {
      var firstName = "";
      if(typeof(this.$store.state.users.usermin) != 'undefined')
      {
        firstName  = this.$store.state.users.usermin.firstName;
      }
      return firstName;
    },
    displayName() {
      var name = "";
      var titel = "";

      if(typeof(this.$store.state.users.usermin) != 'undefined')
      {
        name = this.firstName + " " +
                 this.$store.state.users.usermin.lastName;
        titel = this.$store.state.users.usermin.titel;
      }

      if(titel != "")
        name = titel + " " + name;        

      return  name;
      
    },
    displayEmail() {
      
      var email = "";
      if(typeof(this.$store.state.users.usermin) != 'undefined')
        email=this.$store.state.users.usermin.email;

      return email;
    },
    displayPartnerID() {
      
      var id = "";
      if(typeof(this.$store.state.users.usermin) != 'undefined')
        id=this.$store.state.users.usermin.partnerID;

      return id;
    },    
    displayUserImg() {

      var filename = "";
      
      if(typeof(this.$store.state.users.usermin) != 'undefined')
        filename = this.$store.state.users.usermin.user_img; 
      
      return filename;
    }
  },
  methods: {
    ...helperMethods,
    handleWindowResize(event) {
      this.windowWidth = event.currentTarget.innerWidth;
      this.setSidebar();
    },
    setSidebar() {
      if (this.windowWidth < 1170) {
        this.$store.commit("sidebar/IS_SIDEBAR_ACTIVE", false);
        this.doNotClose = false;
      } else {
        this.$store.commit("sidebar/IS_SIDEBAR_ACTIVE", true);
        this.doNotClose = true;
      }
    },
      LogoutUser: function ()
      {
        const { dispatch } = this.$store;
        dispatch('authentication/logout');
      }
  },
  mounted() {
    this.$nextTick(() => {
      window.addEventListener("resize", this.handleWindowResize);
    });
    this.setSidebar();
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.handleWindowResize);
    this.setSidebar();
  }

};
</script>
<style>
  .vs-sidebar--header
  {
    border-bottom-color: #4F5467 !important;
  }
  .vs-sidebar--item{
    border-radius: 8px !important;
  }
  .vs-sidebar--item:last-child{
    border-bottom: 0px !important;
    margin-bottom:3px;
  }
  .vs-sidebar-group
  {
    border: 1px rgb(210, 210, 210) solid !important;
    border-radius: 8px;
    background: rgb(250, 250, 250);
    margin-top:3px;
    margin-bottom:3px;
  }
    .vs-sidebar-group > h4 > i{
      top:4px;
    }
  .vs-sidebar-group > h4{
    font-weight: bold;
    border-radius: 8px;
    margin:0px;
    vertical-align: middle;
  }
  ul.vs-sidebar--group-items{
    margin-bottom:0px !important;
    border-radius: 8px;
  }
</style>