<template>
  <div>
    <vs-input
      icon-after="true"
      icon="search"
      class="text-muted hiddenMobile"
      :placeholder="$t('SEARCH.PLACEHOLDER')"
      v-model="search"
      v-on:icon-click="searchText"
        @keyup.enter="searchText"/>
    <vs-avatar class="hiddenDesktop" color="none" icon="search" @click="popupMobile=true"/>
    <vs-popup :active.sync="popupMobile" :title="$t('SEARCH.TITLE')" class="searchResults">
      <vs-input
      icon-after="true"
      icon="search"
      class="text-muted width100proz"
      :placeholder="$t('SEARCH.PLACEHOLDER')"
      v-model="search"
      v-on:icon-click="searchText"
        @keyup.enter="searchText"/>
    </vs-popup>
    <vs-popup :active.sync="popupActive" :title="$t('SEARCH.TITLE')" class="searchResults">
        <vs-list>
          <template v-for="(grp, index) in results">
              <vs-list-header :key="'grp'+index" :icon="grp.icon" :title="GetSearchResultTypeText(grp.type)"></vs-list-header>
              <template v-for="(result,idx) in grp.results">
                <vs-list-item :key="'res'+idx" :icon="result.icon" :title="result.name" :subtitle="result.resulttext">
                  <ButtonList  :key="'btnlist'+idx" :buttonArray="GetButtonsForResult(result.goTo)" v-on:BtnClicked="ButtonListClickedResult" ref="BtnListResult"></ButtonList>
                </vs-list-item>
              </template>
          </template>
        </vs-list>
    </vs-popup>
    <AddDebitorDlg ref="addDebitorDlg" v-on:Accepted="AddDebitorAccepted"></AddDebitorDlg>
  </div>
</template>

<script>
import codeHelper from '../../helper/staticCodeHelper';
import enumHelper from '../../helper/enumHelper';
import ButtonList from './ButtonList.vue';
import {searchResultType} from '../../helper/enumHelper';
import AddDebitorDlg from './dialogs/AddDebitorDlg.vue';

export default {
  name: "Search",
  props:[],
  components:{
    ButtonList,
    AddDebitorDlg
  },
  data: function (){
    return {
      results:[],
      debitors:[],
      popupActive:false,
      popupMobile:false,
      search:""
    }
  },
  created(){
    this.$store.dispatch('debitor/getMyDebitors',{ root: true },{ root: true }); 
  },
  methods:{
      ...codeHelper,
      ...enumHelper,

      searchText(){
        this.popupMobile = false;
        if(this.search.length > 3 || !isNaN(this.search))
        {
          this.$store.dispatch('debitor/getMyDebitors',{ root: true },{ root: true }); 

          this.$store.dispatch('search/searchCredDeb', {'text':this.search})
          .then(response => {
            if(this.IsNotNull(response) && response.length > 0)
            {
              this.search = "";
              this.popupActive = true;
            }
            else
            {
              this.$store.dispatch('alertqueue/addWarning', {'titel':this.$t('COM.WARNING.TITEL'),'text':this.$t('SEARCH.WARNING').replace("*1*",this.search)});
            }
          }, 
          error =>{
            this.$store.dispatch('alertqueue/addError', {'titel':this.$t('COM.ERROR.TITEL'),'text':this.$t('SEARCH.ERROR').replace("*1*",this.search)+' '+error});
            this.search = "";
          }); 
        }
        else
        {
          this.$store.dispatch('alertqueue/addWarning', {'titel':this.$t('COM.WARNING.TITEL'),'text':this.$t('SEARCH.TOO_SHORT').replace("*1*",this.search)});
          this.search = "";
        }
      },
      GetDebitorById(id){

        var debitor = null;

        if(this.$store.state.debitor.debitors.data != null)
        {
          for(var i = 0; i < this.$store.state.debitor.debitors.data.length; i++)
          {
            if(this.$store.state.debitor.debitors.data[i].debitor.id == id)
            {
              debitor = this.$store.state.debitor.debitors.data[i];
              break;
            }
          }
        }
        return debitor;
    
      },
      GetButtonsForResult(targets){
        
        var buttons = [];
        if(this.IsNotNull(targets))
        {
          if(targets.length > 0)
          {
            targets.forEach(target => {
              var btn = {label:this.$t(target.name) +", "+ target.description,color:'primary',type:'filled',icon:'open_in_new',show:true, disabled:false,data:{id:target.id1, id2:target.id2, type:target.type}};
              buttons.push(btn);
            });
          }
        }

        return buttons;
      },

      ButtonListClickedResult(index, data){

        this.popupActive = false;

        switch(data.type)
        {
          case searchResultType.DEBITOR:

            var deb = this.GetDebitorById(data.id);
            if(deb != null)
            {
              this.$refs.addDebitorDlg.InitDlgData(deb);
            }
            break;
          case searchResultType.DEBITOR_CONTACT:
            this.$router.push({ name: 'DebitorContact', params: { debitorcontact_fk: data.id } });
            break; 
          case searchResultType.INVOICE:
            this.$router.push({ name: 'Order', params: { order_fk: data.id } });
            break;  
          case searchResultType.OFFER:
            this.$router.push({ name: 'Offer', params: { offer_fk: data.id, editable:this.isEditOfferAllowed(data.id2), for_partnerorder_fk:0} });
            break;                                        
        }
        
      },
      AddDebitorAccepted(){

      } 
  },
  computed:{
    getResults() {
      var data = [];
      if(this.$store.state.search.cred_deb.data != null)
      {
        data = this.$store.state.search.cred_deb.data
      }
      return data;
    },

  },
  watch:{
      getResults(value) {
        
        this.results = value;
      }
  }
};
</script>
<style >
div.searchResults > div.vs-popup {
  max-width: 450px !important;
}
div.searchResults > div.vs-popup > div.vs-popup--content{
  max-height: 600px !important;
}
</style>