<template>
  <div>
    <img :src="require('./../../assets/images/logo/ie_logo_menu.png')"/>
    <vs-progress :percent="percentage" color="primary"></vs-progress>
<!--     <vs-list>
      <template v-for="(mem, index) in loading_stati" >
        <vs-list-item :key="'loading_'+index" :title="mem.type">
            <vs-checkbox disabled="true" v-model="mem.loaded"></vs-checkbox>
        </vs-list-item>
      </template>
    </vs-list> -->
  </div>
</template>
<script>

export default {
  name: "LoadingStatus",
  props:[],
   data: function (){
     return {
     }
  },
  computed:{
    percentage(){
      var ctr_loaded = 0;
      this.$store.state.maincontainer.loadingstatus.stati.forEach(element => {
        if(element.loaded == true)
        {
          ctr_loaded++;
        }
      });
      return 100/this.GetMaxStati()*ctr_loaded;
    },
    loading_stati(){
      return this.$store.state.maincontainer.loadingstatus.stati;
    }
  },
  methods:{
    GetMaxStati(){
      return this.$store.state.maincontainer.loadingstatus.stati.length;
    }
  },
  watch:{
  }
};
</script>