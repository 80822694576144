	export default [{
			url: "/dashboard",
			name: "Dashboard",
			icon: "description",
			right:{subject:"common",action:"show"}
		},
		{
			url: "/debitors",
			name: "Kunden",
			icon: "people",
			right:{subject:"debitors",action:"show"},
			sublinks:
			[
				{
					url: "/debitors",
					name: "Kundenübersicht",
					icon: "people",
					right:{subject:"debitors",action:"show"}
				},
				{
					url: "/debitorcontacts",
					name: "Stammblätter",
					icon: "people",
					right:{subject:"offer",action:"create"}
				},
				{
					url: "/offers",
					name: "Angebote",
					icon: "assignment",
					right:{subject:"offer",action:"show"}
				},
				{
					url: "/invoices",
					name: "Rechnungen",
					icon: "receipt",
					right:{subject:"invoice",action:"show"}
				},
				{
					url: "/orders",
					name: "Aufträge",
					icon: "receipt",
					right:{subject:"order",action:"show"}
				}
			]
		},		
		{
			url: "/partnerorders",
			name: "Bestellungen",
			icon: "shopping_cart",
			right:{subject:"partnerorder",action:"show"}
		},
		{
			url: "/partnerordersforme",
			name: "Bestellungs Eingang",
			icon: "shopping_cart",
			right:{subject:"partnerorderforme",action:"show"}
		},
		{
			url: "/timerecs",
			name: "Zeiterfassung",
			icon: "timer",
			right:{subject:"timerec",action:"show"}
		},
		{
			url: "/payments",
			name: "Zahlungen",
			icon: "account_balance",
			right:{subject:"payment",action:"show"}
		},
		{
			url: "/messages",
			name: "Benachrichtigungen",
			icon: "message",
			right:{subject:"common",action:"show"}
		},
		{
			url: "/myaddresses",
			name: "Meine Adressen",
			icon: "mail",
			right:{subject:"common",action:"show"}
		},
		{
			url: "/bankaccounts",
			name: "Bankverbindungen",
			icon: "account_balance",
			right:{subject:"common",action:"show"}
		},
		{
			url: "/prices",
			name: "Preise",
			icon: "euro_symbol",
			right:{subject:"common",action:"show"}
		},				
		{
			url: "/documents",
			name: "Dokumente",
			icon: "picture_as_pdf",
			right:{subject:"documents",action:"show"}
		},
		{
			url: "/downloads",
			name: "Downloads",
			icon: "get_app",
			right:{subject:"download",action:"show"}
		},
		{
			url: "/confirmations",
			name: "Freigaben",
			icon: "check",
			right:{subject:"common",action:"show"}
		},
		{
			url: "/mainsettings",
			name: "Einstellungen",
			icon: "settings",
			right:{subject:"mainsettings",action:"show"}
		},
		{
			url: "/useradmin",
			name: "Verwaltung",
			icon: "person",
			right:{subject:"common",action:"show"},
			sublinks:[
				{
					url: "/useradmin",
					name: "Benutzerverwaltung",
					icon: "person",
					right:{subject:"user",action:"show"}
				},
				{
					url: "/groupadmin",
					name: "Benutzer Gruppen",
					icon: "group",
					right:{subject:"group",action:"show"}
				},
				{
					url: "/companyadmin",
					name: "Unternehmen Verwaltung",
					icon: "apartment",
					right:{subject:"companylist",action:"show"}
				},	
				{
					url: "/companygroupadmin",
					name: "Unternehmen Gruppen",
					icon: "apartment",
					right:{subject:"companygroup",action:"show"}
				},
				{
					url: "/partnerdiscounts",
					name: "Partner Rabatt",
					icon: "euro",
					right:{subject:"partnerdiscount",action:"show"}
				},
				{
					url: "/specialdiscounts",
					name: "Sonder Rabatt",
					icon: "euro",
					right:{subject:"partnerdiscount",action:"show"}
				},
				{
					url: "/productadmin",
					name: "Produktverwaltung",
					icon: "loyalty",
					right:{subject:"productadmin",action:"show"},
					
				},
				{
					url: "/productgroups",
					name: "Produktgruppen/Kategorien",
					icon: "loyalty",
					right:{subject:"productadmin",action:"show"}
				},
				{
					url: "/motifadmin",
					name: "Bildmotive",
					icon: "collections",
					right:{subject:"motif",action:"show"},
					
				},
				{
					url: "/countryadmin",
					name: "Länderverwaltung",
					icon: "language",
					right:{subject:"countryadmin",action:"show"}
				},	
				{
					url: "/currencyadmin",
					name: "Währungen",
					icon: "euro_symbol",
					right:{subject:"currencyadmin",action:"show"}
				},	
				{
					url: "/pricelists",
					name: "Preislistenverwaltung",
					icon: "assignment",
					right:{subject:"pricelists",action:"show"}
				},
				{
					url: "/fixtext",
					name: "Fixtexte",
					icon: "format_quote",
					right:{subject:"fixtext",action:"show"}
				},
				{
					url: "/deliveryconditions",
					name: "Lieferbedingungen",
					icon: "local_shipping",
					right:{subject:"deliverycondition",action:"show"}
				},
				{
					url: "/paymentconditions",
					name: "Zahlungesbedingungen",
					icon: "euro",
					right:{subject:"deliverycondition",action:"show"}// Selbes Recht wie für Lieferbedingungen
				},
				{
					url: "/timemodels",
					name: "Zeitmodelle",
					icon: "timer",
					right:{subject:"timerec",action:"show"}// Selbes Recht wie für Zeitschreibung
				}
			]
		},
		{
			url: "/translations",
			name: "Wartung",
			icon: "clear",
			right:{subject:"translations",action:"show"},
			sublinks:
			[
				{
					url: "/translations",
					name: "Übersetzungen",
					icon: "translate",
					right:{subject:"translations",action:"show"}
				},
				{
					url: "/documentqueue",
					name: "PDF Warteschlange",
					icon: "queue",
					right:{subject:"documentqueue",action:"show"}
				},
				{
					url: "/testcalls",
					name: "TESTCALLS",
					icon: "warning",
					right:{subject:"admin",action:"admin"}
				},
			]
		},

	]