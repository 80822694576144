<template>
  <header class="gridx">
    <vs-navbar
      v-model="indexActive"
      :color="topbarColor"
      class="topnavbar"
      text-color="rgba(255,255,255,0.7)"
      active-text-color="rgba(255,255,255,1)"
    >
      <!---
      Template logo
      -->
      <div slot="title" class="themelogo">
        <img :src="logo" v-if="logo" alt="Dashboard" class="cursor-pointer" @click="$router.push('/dashboard')"/>
        <span class="logo-text" v-if="title">{{ title }}</span>
      </div>
      <!---
      Mobile toggle
      -->
      <div slot="title">
        <div class="d-flex">
          <div class="hiddenDesktop cursor-pointer mr-1">
            <Search/>
          </div>          
          <div class="hiddenDesktop cursor-pointer mr-1">
            <vs-avatar :badge="NrUnreadMessages" color="none" icon="message" @click="ShowMessageBar()"/>
          </div>
          <div class="hiddenDesktop cursor-pointer" >
            <vs-avatar :badge="NrUnreadMessages" color="none" icon="menu" @click.stop="activeSidebar"/>
          </div>
        </div>
      </div>      
      <!---
      Craete new dd
      -->
      <Search/>

      <vs-spacer class="hiddenMobile"></vs-spacer>
      <!--
      <div >                      
        <vs-button color="primary" type="filled" @click="LogoutUser">Abmelden</vs-button>
      </div>
        -->
      <div class="ml-3 hiddenMobile">
          <vs-avatar :badge="NrUnreadMessages" color="none" icon="message" @click="ShowMessageBar()"/>
      </div>     
      <!---
      Craete new dd
      -->
      
      <vs-dropdown vs-trigger-click left class="cursor-pointer pr-2 pl-2 ml-1 hiddenMobile">
        <a class="text-white-dark user-image" href="#">
          <img v-bind:src="'data:image/jpeg;base64,'+displayUserImg"  alt="Benutzerbild" />
        </a>
        <vs-dropdown-menu class="topbar-dd">
          <vs-dropdown-item to="/ResetPassword">
            <vs-icon icon="lock" class="mr-1"></vs-icon>Passwort ändern
          </vs-dropdown-item>
        </vs-dropdown-menu>
      </vs-dropdown>
      <vs-button icon="logout" radius class="pr-2 pl-2 ml-1 mr-md-3" @click="LogoutUser()"></vs-button>
    </vs-navbar>
  </header>
</template>

<script>
import helperMethods from '../../../helper/staticFuncHelper.js';
import LocalStorageService from '../../../services/localstorage.service';
import Search from '../../../views/components/Search.vue';

const localStorageService = LocalStorageService.getService();

export default {
  name: "Navbar",
  components:
  {
    Search
  },
  props: {
    topbarColor: {
      type: String,
      default: "#4F5467"
    },
    title: {
      type: String
    },
    logo: {
      type: String
    }
  },
  data: () => ({
    indexActive: 0,
    showToggle: false,
    search: ""
  }),
  created () {
    if(this.$appConfig.signalRDisabled == false)
    {
      // Listen to score changes coming from SignalR events
      this.$sharedHub.startSignalR();
      this.$sharedHub.$on('bannerchanged', this.BannerChanged);
      this.$sharedHub.$on('documentready', this.DocumentReady);
      this.$sharedHub.$on('refreshmessages', this.RefreshMessages);
      this.$sharedHub.$on('signalrconnected', this.SignalConnected);
      this.$sharedHub.$on('UserTest', this.UserTest);
    }
  },
  beforeDestroy () {
    if(this.$appConfig.signalRDisabled == false)
    {
      // Make sure to cleanup SignalR event handlers when removing the component
      this.$sharedHub.$off('bannerchanged', this.BannerChanged);
      this.$sharedHub.$off('documentready', this.DocumentReady);
      this.$sharedHub.$off('refreshmessages', this.RefreshMessages);
      this.$sharedHub.$off('signalrconnected', this.SignalConnected);
      this.$sharedHub.$off('UserTest', this.UserTest);
      this.$sharedHub.stopSignalR();
    }
  },
  methods: {
    ...helperMethods,
    //This is for sidebar trigger in mobile
    activeSidebar() {
      this.$store.commit("sidebar/IS_SIDEBAR_ACTIVE", true);
    },
    LogoutUser: function ()
      {
        const { dispatch } = this.$store;
        dispatch('authentication/logout');
      },
    ShowMessageBar(){
      this.$emit('ShowMessageBar');
      this.RefreshMessages();
    },
    SignalConnected(){
      this.$store.dispatch('maincontainer/setSignalRConnectionStatus', true,{root: true},{root: true}); 
    },
    UserTest(){
      this.$store.dispatch('alertqueue/addSuccess', {'titel':this.$t('COM.SUCCESS.TITEL'),'text':'ICH WURDE VON SIGNALR GESENDET!!!!'});
    },
    BannerChanged(){
        const loggedIn = localStorageService.getAuthToken();
        if(loggedIn)
        {
          // Aktionen pollen für die man eingeloggt sein muss
          this.$store.dispatch('polling/getPrivateBanners', { root: true },{ root: true });
          this.$store.dispatch('polling/getPublicBanners', { root: true },{ root: true }); 
          
        }
    },
    RefreshMessages(){
      const loggedIn = localStorageService.getAuthToken();
        if(loggedIn)
        {
          this.$store.dispatch('messages/getNrUnreadMessages',{ root: true },{ root: true });

          // Nachrichten abfragen
          if(this.$store.state.messages.lastUpdate == null)
          {
            // Alle abfragen
            this.$store.dispatch('messages/getMessages', { root: true },{ root: true }); 
          }
          else
          {
            // Nur die ungelesenen seit der letzten Abfrage
            this.$store.dispatch('messages/getUnreadMessages', this.$store.state.messages.lastUpdate ); 
          }
        }
    }
  },
  computed:  {
    displayUserImg() {
      
      var img = "";
      
      if(typeof(this.$store.state.users.usermin) != 'undefined')
        img = this.$store.state.users.usermin.user_img; 
      
      return img;
    },
    NrUnreadMessages() {
        var ctr = 0;
        if(this.$store.state.messages.messages.data != null)
        {
          ctr = this.$store.state.messages.messages.data.filter(mes => !mes.read).length;
        }
        return ctr;
      }    
  } 
};
</script>
