<template>
  <div class="main-wrapper">
    <!---Navigation-->
    <Navbar
      :topbarColor="topbarColor"
      :logo="require('@/assets/images/logo/ie_logo_weiss40px.png')"
      :title="getTitle()"
      v-on:ShowMessageBar="ShowMessageBar"
    />
    
    <!---Sidebar-->
    <SideBar parent=".main-wrapper" :sidebarLinks="sidebarLinks" v-show="all_loaded" />
    <!---Page Container-->
    <div class="main-container-fluid">
      <AlertQueue></AlertQueue>
      
      <div class="child"  v-show="!all_loaded">
        <LoadingStatus></LoadingStatus>
        <div v-show="testActive">
          <div> <vs-checkbox v-model="languages_loaded">Sprachen</vs-checkbox></div>
          <div class="mt-3"><vs-checkbox v-model="countries_loaded">Länder</vs-checkbox></div>
          <div class="mt-3"><vs-checkbox v-model="sections_loaded">Sektions</vs-checkbox></div>
          <div class="mt-3"><vs-checkbox v-model="currencies_loaded">Währungen</vs-checkbox></div>
          <div class="mt-3"><vs-checkbox v-model="mwst_loaded">Steuersätze</vs-checkbox></div>
          <div class="mt-3"><vs-checkbox v-model="debitors_loaded">Kunden-Vorschau</vs-checkbox></div>
          <div class="mt-3"><vs-checkbox v-model="rals_loaded">RAL Farben</vs-checkbox></div>
          <div class="mt-3"><vs-checkbox v-model="usermin_loaded">Benutzerdaten</vs-checkbox></div>
          <div class="mt-3"><vs-checkbox v-model="translation_loaded">Übersetzungen</vs-checkbox></div>
          <div class="mt-3"><vs-checkbox v-model="motifcategories_loaded">Bildmotiv-Kategorien</vs-checkbox></div>
          <div class="mt-3"><vs-checkbox v-model="motifs_loaded">Bildmotive</vs-checkbox></div>
        </div>
      </div>
      <router-view v-show="all_loaded"></router-view>
    </div>
  
    <Messagebar ref="myMessagebar"/>

  </div>
</template>

<script>
import Navbar from "@/layout/full/header/Navbar.vue";
import SideBar from "@/layout/full/sidebar/SideBar.vue";
import sidebarLinks from "@/layout/full/sidebar/sidebarlinks.js";
import AlertQueue from "../AlertQueue";
import {allLanguageTexts} from "@/helper/i18nHelper";
import Messagebar from "@/views/components/messages/Messagebar.vue";
import LoadingStatus from "@/views/components/LoadingStatus.vue";

export default {
  name: "MainContainer",
  components: {
    Navbar,
    SideBar,
    AlertQueue,
    Messagebar,
    LoadingStatus
  },
  data: () => ({
    topbarColor: "#4F5467",
    sidebarLinks: sidebarLinks
  }), 
  computed:{
    testActive(){
      return this.$appConfig.testActive;
    },
    all_loaded(){
      return this.$store.state.maincontainer.loadingstatus.all_loaded;
    },
    languages_loaded(){
      return this.getLoadingStatus("language");
    },
    countries_loaded(){
      return this.getLoadingStatus("country");
    },
    sections_loaded(){
      return this.getLoadingStatus("section");
    },
    currencies_loaded(){
      return this.getLoadingStatus("currency");
    },   
    mwst_loaded(){
      return this.getLoadingStatus("mwst");
    },  
    products_loaded(){
      return this.getLoadingStatus("product");
    },  
    debitors_loaded(){
      return this.getLoadingStatus("debitor");
    },   
    rals_loaded(){
      return this.getLoadingStatus("ral");
    },
    usermin_loaded(){
      return this.getLoadingStatus("usermin");
    },      
    translation_loaded(){
      return this.getLoadingStatus("translation");
    },   
    motifcategories_loaded(){
      return this.getLoadingStatus("motifcategories");
    },
    motifs_loaded(){
      return this.getLoadingStatus("motifs");
    },           
  },
  methods:{
    getTitle(){
      var title = "Vertriebssystem 2.0";
      if(this.$appConfig.testActive == true)
      {
        title += " TEST";
      }
      return title;
    },
    getLoadingStatus(type)
    {
      var retVal = false;
      for(var i =0; i < this.$store.state.maincontainer.loadingstatus.stati.length; i++)
      {
        if(this.$store.state.maincontainer.loadingstatus.stati[i].type == type)
        {
          retVal = this.$store.state.maincontainer.loadingstatus.stati[i].loaded;
          break;
        }
      }
      
      return retVal;
    },
    loadTranslations:function(language_fk)
    {
      // wenn UserMinData da sind, entsprechend die Texte laut Sprache laden
      this.$store.dispatch('language/getTranslations', {language_fk:language_fk,section_fk:-1})
          .then(response => {
            if(response != null)
            {
              try {
                    allLanguageTexts.de = {};
                    for(var i = 0; i < response.length;i++)
                    {
                      var pl = response[i].placeholder;
                      var txt = "";
                      for(var j=0; j < response[i].texts.length;j++)
                      {
                        if(response[i].texts[j].language_fk == language_fk)
                        {
                          txt = response[i].texts[j].translation;
                          break;
                        }
                      }
                      allLanguageTexts.de[pl] = txt;
                    }
                  }
                  catch(err) {
                    alert(err.message);
                  }
              

            }
          }, 
          error =>{
            this.$store.dispatch('alertqueue/addError', {'titel':'Fehler!','text':'Übersetzungen konnten nicht geladen werden'+error});   
          });
    },
    ShowMessageBar(){
      this.$refs.myMessagebar.ShowMessageBar();
    }
  },
  created() {
    // Sprachenliste laden
    if(this.languages_loaded == false)
      this.$store.dispatch('language/getLanguages', { root: true },{ root: true }); 
    // Länderliste laden
    if(this.countries_loaded == false)
      this.$store.dispatch('country/getCountries', { root: true },{ root: true });  
    // Translation Sections laden
    if(this.sections_loaded == false)
      this.$store.dispatch('language/getSections', { root: true },{ root: true }); 
    // Währungen laden
    if(this.currencies_loaded == false)
    {
     this.$store.dispatch('currencies/getCurrencies', { root: true },{ root: true });
    }
    // Alle Mwst Sätze laden
    if(this.mwst_loaded == false)
      this.$store.dispatch('country/getMwstAll', { root: true },{ root: true });  
    // Produkte des Unternehmens laden
    if(this.products_loaded == false)
      this.$store.dispatch('products/getProductAdminList', { root: true },{ root: true }); 
    // Kunden-Dropdown Inhalt laden
    if(this.debitors_loaded == false)
      this.$store.dispatch('debitor/getMyDebitorsMin', { root: true },{ root: true }); 
    // Ral Farben laden
    if(this.rals_loaded == false)
    {
      this.$store.dispatch('ralcolor/getAllRalColors',{ root: true },{ root: true });
    }

    if(this.motifcategories_loaded == false)
    {
      this.$store.dispatch('motif/getMotifCategories',{ root: true },{ root: true });
    }

    if(this.motifs_loaded == false)
    {
    this.$store.dispatch('motif/getMotifs',{ root: true },{ root: true });
    }

    // Benutzerinfo laden
    if(this.usermin_loaded == false)
    {
        this.$store.dispatch('users/getUserMinData', { root: true },{ root: true })
        .then(mindata => {
          if(mindata != null)
          {
            this.loadTranslations(mindata.language_fk);
          }
      }, 
      error =>{
        this.$store.dispatch('alertqueue/addError', {'titel':'Fehler!','text':'Benutzerdaten konnten nicht geladen werden'+error});  
      });
    }
    else
    {
      this.loadTranslations(this.$store.state.users.usermin.language_fk);
    }
    // Benutzerrechte laden und zuweisen (bei jedem Pagerefresh bzw wenn der MainContainer neu geladen wird - von login auf eine andere seite zb)
    this.$store.dispatch('users/getUserRoles')
        .then(response => {
          if(response != null)
          {
            this.$ability.update(response);
          }
        }, 
        error =>{
          this.$store.dispatch('alertqueue/addError', {'titel':'Fehler!','text':'Benutzerrechte konnte nicht geladen werden'+error});   
        });
    // Einmal alle Banner laden, refresh erfolgt dann über polling
    this.$store.dispatch('polling/getPublicBanners', { root: true },{ root: true }); 
    this.$store.dispatch('polling/getPrivateBanners', { root: true },{ root: true }); 
    // Nachrichten abfragen
    if(this.$store.state.messages.lastUpdate == null)
    {
      // Alle abfragen
      this.$store.dispatch('messages/getMessages', { root: true },{ root: true }); 
    }
    else
    {
      // Nur die ungelesenen seit der letzten Abfrage
      this.$store.dispatch('messages/getUnreadMessages', this.$store.state.messages.lastUpdate ); 
    }
  }


};
</script>
<style scoped>
.child {
  /* Center vertically and horizontally */
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
</style>