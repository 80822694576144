<template>
    <div>
        <div class="message-container" >

            <div class="d-flex align-items-center message_header" :class="colorClass">
                <h5 @click="ShowMessage()">{{Title}}</h5>
                <div class="btn-alignment ml-auto text-right ">
                    <vs-button v-if="downloadable" size="small" :color="Color" type="flat" icon="get_app"></vs-button>
                    <vs-button size="small" :color="Color" type="flat" icon="delete" @click="DeleteMessage()"></vs-button>
                    <vs-button size="small" :color="Color" type="flat" icon="close" @click="MarkAsRead()"></vs-button>
                </div>
            </div>

            <div class="mb-2"><span class="message_from text-muted " >{{From}}</span></div>
            <div @click="ShowMessage()">
                {{messagetext}}
            </div>
        </div>
        <MessagePopUp ref="myMessagePopUp" :Guid="Guid" :Title="Title" :Text="Text" :DateTime="DateTime" :From="From" :DocumentIds="DocumentIds" />
    </div>
</template>
<script>

import MessagePopUp from './MessagePopUp';

export default {
    name: "MessagebarItem",
    components:{
        MessagePopUp
    },
    props:['Title','From','Text','Color','Guid','DateTime', 'DocumentIds'],
    data: function (){
        return {
        }
  },
  methods:{
    DeleteMessage(){
        this.$store.dispatch('messages/markAsDeleted', this.Guid ); 
    },
    MarkAsRead(){
        this.$store.dispatch('messages/markAsRead', this.Guid ); 
    },
    ShowMessage(){
        this.$refs.myMessagePopUp.ShowPopUp();
    }
  },
  computed:{
      colorClass(){
          var retVal = "state_normal";
          switch(this.Color)
          {
              case "danger":
                  {
                      retVal = "state_danger";
                      break;
                  }
              case "warning":
                  {
                      retVal = "state_warning";
                      break;
                  }
              case "success":
                  {
                      retVal = "state_success";
                      break;
                  }                                    
              case "primary":
              default:
                  {
                      // irgend eine farbe, wird 1:1 übernommen
                      break;
                  }
          }
        return retVal;
      },
      messagetext(){
          if(this.Text.length > 50)
          {
              return this.Text.slice(0, 50)+"...";
          }
          else
          {
              return this.Text;
          }
      },
      downloadable(){
          var retVal = false;
          if(this.FileGuids != null)
          {
            retVal = this.FileGuids.length > 0;
          }
          return retVal;
      }
  }
};
</script>
<style scoped>
.message_header{
  border-bottom: 3px solid;
}
.state_normal{
 border-color: gray;
}
.state_danger{
    border-color:var(--danger) !important;
}
.state_warning{
    border-color:var(--warning) !important;
}
.state_success{
    border-color:var(--success) !important;
}
</style>